import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const UserGuide = () => {
  const headingbanner = {
    title: `User Guides`,
    content: `A Customer Success Management Platform`,
  };

  const data = {
    images: ["user-guides.webp", "user-guides-2.webp"],
    firstAlt: "UI UX Screen To Monitor Navigations",
    firstTitle: "Navigations Screen",
    secondAlt: "Details Of Naviagtions",
    secondTitle: "Naviagtion Results",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["Enterprise"],
      },
      {
        title: "What we did",
        text: ["User Research", "UI UX Designing", "Dashboards and Data Visualisation"],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `The User Guides platform was facing a critical challenge in effectively capturing and utilizing user data to drive product engagement and improve customer experience.`,
          `Despite having a wealth of information at their disposal, the current system fails to provide actionable insights, resulting in missed opportunities for user retention, feature adoption, and overall product success.`,
          `This problem is further exacerbated by a lack of user-friendly tools and an overwhelming amount of data, making it difficult for product teams to identify and address key pain points.`,
          `Consequently, there is an urgent need to enhance the User Guides platform's data analytics capabilities, streamline data visualization and reporting, and empower product teams with actionable insights to optimize product performance and deliver exceptional user experiences.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `We implemented the following solutions in the platform to capture and utilize user data effectively-`,
        ],
        list: [
          `Implemented a comprehensive data management system to centralize and organize user data from various sources, such as user interactions, feedback, and usage patterns.`,
          `Employed advanced analytical techniques, such as machine learning algorithms, to identify patterns and used predictive analytics to forecast user behaviour and preferences enabling proactive decision-making.`,
          `Developed user-friendly dashboards to present data in a visually appealing and easy-to-understand manner.`,
          `Leverage the analyzed user data to personalize user experiences and deliver targeted content based on individual preferences and needs.`,
          `Implemented A/B testing to experiment with different features and user interactions, and analyze the impact on user behaviour.`,
          `Regularly evaluate and update the data analytics tools and technologies to keep up with evolving industry standards and user expectations.`,
        ]
      },
    ],
    image: ["user-guides-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: `Create Navigation`,
    para: [
      `Create multiple navigation for different modules according to the customer and publish those module.`,
    ],
    content: {
      imageAlt: 'UI UX Screen To Monitor Navigations',
      imageTitle: 'Navigations Screen',
      image: ["user-guides-projectone.webp"],
      text: `We also provided status option for the user to check the status of their navigation for better productivity. They can also make edit on the navigation for improved performance.`,
    },
    content2: {
      imageAlt: 'Brief detail of the navigation setting',
      imageTitle: 'Navigation Overview',
      image: ["user-guides-projectone-2.webp"],
      text: `Users can create new navigation with just few steps with our simplified solutions. They just have to set their navigation goal, create steps and choose from the trigger type to setup navigation action.`,
    },
  };

  const projectData2 = {
    title: `Guide Customers by Tooltips`,
    para: [
      `Users can create and customize the navigation steps based on their target audience in order to guide them through each steps.`,
    ],
    content: {
      image: ["user-guides-projecttwo.webp"],
      imageAlt: 'Choose Navigation Design',
      imageTitle: 'Announcement Editor',
    },
  };

  const projectData3 = {
    title: `Choose, Build and Publish`,
    para: [
      `With an invtuitive interface and robust features, our platform offers a seamless end-to-end solution for creating, building, and publishing content.`,
    ],
    content: {
      image: [
        "user-guides-projectthree.webp",
        "user-guides-projectthree-2.webp",
        "user-guides-projectthree-3.webp",
        "user-guides-projectthree-4.webp",
        "user-guides-projectthree-5.webp",
      ],
      firstAlt: 'Create content and select the button design',
      firstTitle: 'Step 1',
      secondAlt: 'Primary button and other settings',
      secondTitle: 'Other Settings',
      thirdAlt: 'Choose from the in-built templates',
      thirdTitle: 'Choose Templates',
      fourthAlt: 'Select the template and launch ',
      fourthTitle: 'Launch',
      fifthAlt: 'Enter URL to launch',
      fifthTitle: 'Enter URL',
    },
  };

  const conclusionData = [
    {
      para: [
        `With the implementation of this comprehensive solution, the User Guides platform is well-positioned to unlock the full potential of its- `,
      ],
      list: [
        `User Data`,
        `Driving Growth`,
        `User Satisfaction`,
        `Billing`,
        `Product Success`,
      ],
      para2: [
        `By continuously iterating and improving upon this solution, User Guides can remain at the forefront of the industry, helping companies around the world create impactful and user-centric digital products.`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={10} />
      </div>
    </Layout>
  );
};

export default UserGuide;

export const Head = () => (
  <>
    <meta name="keywords" content="user guide, ui ux design, ui ux, project, case study" />
    <Seo title="Userguides- Octet Design Studio" description="A customer success platform assisting SMEs to offer enhanced digital experiences by offering User Research, UI UX Designing and Data visualisation services." />
  </>
)